import { useState } from "react";
import classes from "./GeneratePrice.module.css";
import {
  Box,
  Button,
  ListItemIcon,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { List, ListItem, ListItemText } from "@mui/material";
import { FiberManualRecord } from "@mui/icons-material";
import { motion } from "framer-motion";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import PersonIcon from "@mui/icons-material/Person";
import useCustomObserver from "../../hooks/customObserver";

// cateringKG = parents * 0.25
const packageArray = [
  {
    parents: 20,
    children: 20,
    price: 215,
    priceWithCatering: 360,
    cateringKG: 5,
  },
  {
    parents: 25,
    children: 25,
    price: 240,
    priceWithCatering: 410,
    cateringKG: 6.25,
  },
  {
    parents: 30,
    children: 30,
    price: 270,
    priceWithCatering: 495,
    cateringKG: 7.5,
  },
  {
    parents: 35,
    children: 35,
    price: 305,
    priceWithCatering: 560,
    cateringKG: 8.75,
  },
  {
    parents: 40,
    children: 40,
    price: 325,
    priceWithCatering: 615,
    cateringKG: 10,
  },
  {
    parents: 45,
    children: 45,
    price: 1000,
    priceWithCatering: 685,
    cateringKG: 11.25,
  },
  {
    parents: 50,
    children: 50,
    price: 1000,
    priceWithCatering: 755,
    cateringKG: 12.5,
  },
  {
    parents: 55,
    children: 55,
    price: 1000,
    priceWithCatering: 810,
    cateringKG: 13.75,
  },
];

const generateListItem = (
  text: string,
  smallDevice: boolean,
  index: number
) => {
  return (
    <ListItem
      key={index}
      style={{
        paddingTop: "0px",
        paddingBottom: "0px",
        margin: "0px",
      }}
    >
      <ListItemIcon sx={{ minWidth: "22px" }}>
        <FiberManualRecord style={{ fontSize: "small", color: "white" }} />
      </ListItemIcon>

      <ListItemText
        primary={text}
        primaryTypographyProps={{
          style: {
            color: "white",
            fontSize: smallDevice ? "0.85rem" : "1.4rem",
            padding: "0px !important",
            margin: "0px !important",
          },
        }}
      />
    </ListItem>
  );
};

const listItemsTextDefault = [
  "Neograničeno piće za decu i odrasle",
  "Zakup igraonice u trajanju od 2.5 h.",
  "Fotografisanje, elektronska pozivnica",
  "Animatori za decu (crtanje po licu)",
  "Osoblje (konobar i šanker)",
];

const GeneratePrice = () => {
  const smallDevice = useMediaQuery("(max-width: 800px)");
  const [activePrice, setActivePrice] = useState(0);
  const [cateringIncluded, setCateringIncluded] = useState(true);

  const { sectionRef, inView } = useCustomObserver(0.4);

  const listItemsText = [
    ...(cateringIncluded
      ? // ? [
        //     `Ketering za decu i odrasle - ${
        //       packageArray?.[activePrice]?.parents * 0.6
        //     } kg`,
        //   ]
        [`Ketering za decu i odrasle`]
      : []),
    ...listItemsTextDefault,
  ];

  return (
    <Box ref={sectionRef} className={classes.topBox}>
      <motion.div
        initial={{ opacity: 0, y: 70 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 3 }}
      >
        <Box className={classes.topContainer}>
          <Typography
            variant="h3"
            textAlign="center"
            width={"100%"}
            color={"primary.secondary"}
          >
            {"Imamo najpovoljnije cene u gradu, uverite se sami!"}
          </Typography>
          <Box className={classes.container}>
            <Box className={classes.choosePackage}>
              <Box width={"100%"} mb={4}>
                <Typography
                  width={"100%"}
                  textAlign={"center"}
                  variant="h5"
                  color={"primary.secondary"}
                >
                  {"Da li želite naš ketering?"}
                </Typography>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width={"100%"}
                >
                  <Button
                    fullWidth
                    onClick={() => setCateringIncluded(true)}
                    variant={cateringIncluded ? "contained" : "outlined"}
                    color={cateringIncluded ? "secondary" : "primary"}
                    style={{ margin: "10px" }}
                  >
                    {"Da"}
                  </Button>
                  <Button
                    fullWidth
                    onClick={() => setCateringIncluded(false)}
                    variant={!cateringIncluded ? "contained" : "outlined"}
                    color={!cateringIncluded ? "secondary" : "primary"}
                    style={{ margin: "10px" }}
                  >
                    {"Ne"}
                  </Button>
                </Box>
              </Box>
              <Typography
                width={"100%"}
                textAlign={"center"}
                variant="h5"
                color={"primary.secondary"}
              >
                {"Broj gostiju, odrasli i deca"}
              </Typography>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                flexWrap={"wrap"}
              >
                {packageArray.map((element, index) => (
                  <Button
                    key={index}
                    onClick={() => setActivePrice(index)}
                    variant={activePrice === index ? "contained" : "outlined"}
                    color={activePrice === index ? "secondary" : "primary"}
                    style={{ margin: "5px", width: "140px", height: "35px" }}
                  >
                    {`${element?.parents}`}
                    <PersonIcon sx={{ margin: "0 3px" }} fontSize="small" />
                    {`+ ${element?.children}`}
                    <ChildCareIcon sx={{ margin: "0 3px" }} fontSize="small" />
                  </Button>
                ))}
              </Box>
            </Box>
            <Paper className={classes.mainImage}>
              <Box className={classes.image_container}>
                <motion.div
                  key={`${activePrice}-${cateringIncluded ? "1" : "0"}`}
                  initial={{ width: "0%" }}
                  animate={{ width: "100%" }}
                  transition={{ duration: 1 }}
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    display: "block",
                  }}
                >
                  <Typography
                    color={"white"}
                    variant="h2"
                    fontSize={
                      smallDevice ? "39px !important" : "59px !important"
                    }
                    style={{
                      margin: smallDevice
                        ? "40px 0px 25px 120px"
                        : "59px 0px 25px 132px",
                      fontWeight: "bold",
                    }}
                  >
                    {`${
                      packageArray?.[activePrice]?.[
                        cateringIncluded ? "priceWithCatering" : "price"
                      ]
                    }`}
                  </Typography>
                  <Typography
                    color={"white"}
                    fontWeight={"bold"}
                    variant={smallDevice ? "h5" : "h4"}
                    pl={smallDevice ? "25px" : "0px"}
                  >
                    {`${packageArray?.[activePrice].parents} odraslih i ${packageArray?.[activePrice]?.children} dece`.toUpperCase()}
                  </Typography>

                  <List
                    style={{
                      padding: smallDevice ? "0 0 0 25px" : "0px",
                      marginLeft: smallDevice ? "-8px" : "-15px",
                    }}
                  >
                    {listItemsText.map((text, index) =>
                      generateListItem(text, smallDevice, index)
                    )}
                  </List>
                </motion.div>
              </Box>
            </Paper>
          </Box>
          <List
            style={{
              width: "100%",
              maxWidth: "1300px",
              margin: "10px auto",
              padding: smallDevice ? "0 0 0 25px" : "10px",
            }}
          >
            <ListItem
              key={"1"}
              style={{
                paddingTop: "0px",
                paddingBottom: "0px",
                margin: "0px",
              }}
            >
              <ListItemIcon sx={{ minWidth: "22px" }}>
                <FiberManualRecord
                  style={{ fontSize: "small", color: "white" }}
                />
              </ListItemIcon>

              <ListItemText
                primary={
                  "Cene su formirane prema broju odraslih osoba. " +
                  "Deca su ukljucena u cenu paketa i njihov broj je informativnog karaktera."
                }
                primaryTypographyProps={{
                  style: {
                    color: "white",
                    fontSize: smallDevice ? "0.85rem" : "1.4rem",
                    padding: "0px !important",
                    margin: "0px !important",
                  },
                }}
              />
            </ListItem>
            <ListItem
              key={"2"}
              style={{
                paddingTop: "0px",
                paddingBottom: "0px",
                margin: "0px",
              }}
            >
              <ListItemIcon sx={{ minWidth: "22px" }}>
                <FiberManualRecord
                  style={{ fontSize: "small", color: "white" }}
                />
              </ListItemIcon>

              <ListItemText
                primary={"U paketima se sluzi komadno pice."}
                primaryTypographyProps={{
                  style: {
                    color: "white",
                    fontSize: smallDevice ? "0.85rem" : "1.4rem",
                    padding: "0px !important",
                    margin: "0px !important",
                  },
                }}
              />
            </ListItem>
          </List>
        </Box>
      </motion.div>
    </Box>
  );
};
export default GeneratePrice;
